import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Quill from 'quill';
import { LdsButton, LdsModal, useLdsModal, LdsTable, LdsSearch, LdsIcon, LdsLoadingSpinner, LdsTextField } from "@elilillyco/ux-lds-react";
import { RequestForm } from '../requests/RequestForm';
import { OrcaFormLabelOverrides } from './OrcaFormLabelOverrides';
import { OrcaFormOptions } from './OrcaFormOptions';
import { selectUserData } from "../../redux/slices/userSlice";
import calculatePrioritizationScore from '../../utils/PriorityScore';
import MultiSelectDropdown from './OrcaMultiSelect';

export const OrcaTable = ({ rows, loading, title, displayKey, children, filterColumns, showFilters, actionButtons, cloneModal, updateModal, editModal, viewModal, cloneRequestModal}) => {
  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const [formData, setFormData] = useState({});
  const [sortedRows, setRows] = React.useState(rows);
  const [shownHeaders, setShownHeaders] = useState(displayKey ? Object.keys(displayKey).map((key) => { if (displayKey[key].display) { return key }}) : []);
  const [ modalHeading, setModalHeading ] = useState('Edit Request');
  const [ editable, setEditable ] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [clearFilter, setClearFilter] = useState(false)

  const userData = useSelector(selectUserData);

  const requestMultiSelectColumn = ['requester','business_unit','request_brand','requested_sprint','status','actual_sprint','br_insight_type','capability','brand','indication','sales_force_team']

  // Booleans have no string reprensation - correct this by rendering Yes/No
  const formatValue = (value, columnKey, plainText=false) => {
    if (!value) {
      return ''
    }

    // handle Quill Formatted Text
    let quillValue = null;
    try {
      quillValue = JSON.parse(value);
    } catch (error) {
      quillValue = null;
    }

    if (quillValue != null && quillValue.ops) {
      let hiddenDiv = document.createElement('div');
      hiddenDiv.style.display = 'none';
      const quill = new Quill(hiddenDiv, { theme: 'snow' });
      quill.setContents(quillValue);
      let innerHTMLContent = quill.getSemanticHTML();
      hiddenDiv.remove();
      //Ordered and unordered lists are unstyled by default. Add classes to to bring back the bullet lists and numbers
      if (innerHTMLContent.includes("<ol") || innerHTMLContent.includes("<ul")) {
        innerHTMLContent = innerHTMLContent.replaceAll("<ol", `<ol class="list-decimal list-inside"`).replaceAll("<ul", `<ul class="list-disc list-inside"`);
      }
      let newElement = React.createElement('div', { dangerouslySetInnerHTML: { __html: innerHTMLContent } });
      if (plainText) {
        let parser = new DOMParser();
        let doc = parser.parseFromString(innerHTMLContent, 'text/html');
        let textContent = doc.body.textContent || "";
        return textContent.trim();
      }
      else {
        return newElement
      }
    }

    /*Adding the code to handle NA in metrics library*/
    if (Array.isArray(value)) {
      // Check if the array contains only 'NA'
      if (value.length === 1 && value[0] === 'NA') {
        return 'NA'; // Handle 'NA' differently
      }
      return value.join(', ');
    }
    if (typeof value !== 'string') {
      // If value is not a string, return it as is
      return value;
    }
    //
    if (OrcaFormOptions[columnKey]) {
      if (value.split(';').length > 1) {
        return value.split(';').map((val) => OrcaFormOptions[columnKey].find((option) => option?.value?.toLowerCase() === val.toLowerCase())?.label || val).join(', ');
      } else {
        value = OrcaFormOptions[columnKey].find((option) => option?.value?.toLowerCase() === value?.toLowerCase())?.label || value;
      }
    }
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    if (displayKey && displayKey[columnKey] && displayKey[columnKey].shorten) {
      if (value?.toString().length > 75) {
        return value.toString().substring(0, 75) + '...';
      } else {
        return value;
      }
    }
    return value;
  }

  const isTable = (value) => {
    return value instanceof Array && value.length > 0 && value[0] instanceof Object && Object.keys(value[0]) && Object.keys(value[0]).length > 0
  }

  // Pretty up any key names used as to use them as headers
  const formatHeaderValue = (value) => {
    if (displayKey && displayKey[value] && displayKey[value].title)
    {
      return displayKey[value].title;
    }
    if (OrcaFormLabelOverrides[value]) {
      return OrcaFormLabelOverrides[value];
    }
    return value.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); }).replace(/ ./g, function(str) { return str.toUpperCase(); });
  }

  let initSortKey = '';
  let initSortDir = 'desc'
  if (rows && rows[0] && rows[0]['id']) {
    initSortKey = 'id';
  } else if (rows && rows[0] && rows[0].keys && rows[0].keys.length > 0) {
    initSortKey = rows[0].keys[0];
    initSortDir = 'asc'
  }

  const [order, setOrder] = useState(initSortDir);
  const [sortKey, setSortKey] = useState(initSortKey);
  const [filterValue, setFilterValue] = useState('');
  const [filterOptions, setFilterOptions] = useState({})

  // Filter the rows on state change (user inputs text into search bar)
  useEffect(() => {
    filter(filterValue);
  }, [filterValue] )

  // Update the state when rows are changed via filter or request
  useEffect(() => {
    if (!displayKey) {
      if (isTable(rows) && rows[0]) {
        let headerKey = {}
        Object.keys(rows[0]).map((element) => { headerKey[element] = { 'display' : true } })
        setShownHeaders(Object.keys(headerKey))
      }
    }
    sortAndOrder(sortKey);
    filter(filterValue)
  }, [rows] )

  const updateFilterValues = (event) => {
    const key = event.target.id.substr(7)
    setFilterValues(filterValues => ({ ...filterValues, [key]: event.target.value }))
  }

  const updateMultiSlectFilterValues = (name, selectedOptions) => {
    let data = name;
    // Remove from filter on deselecte option
    if (selectedOptions.length === 0 && name in filterValues) {
      setFilterValues((prevState) => {
        const { [data]: _, ...rest } = prevState;
        return rest;
      });
    } else {
      if (selectedOptions?.length > 0) {
        setFilterValues(filterValues => ({
          ...filterValues,
          [name]: selectedOptions
        }));
      }
    }
  }

  const clearFilterValues = () => {
    Object.keys(filterValues).forEach((key) => { filterValues[key] = '' })
    setFilterValues({...filterValues})
    setClearFilter(true)
  }

  useEffect(() => {
    // Filter columns for respected column select options
    if(!filterOptions.length ){
    const requesterNames = [...new Set(rows.map(item => item?.requester))].map(requester => ({ label: requester, value: requester }));
    const statusNames = [...new Set(rows.map(item => item?.status))].map(status => ({ label: status, value: status }));
    const actual_sprint = [...new Set(rows.map(item => item?.actual_sprint))].map(actual_sprint => ({ label: actual_sprint, value: actual_sprint }));
    const indicationOptions = [...new Set(rows.map(item => item?.indication))].map(indication => ({ label: indication?.toUpperCase(), value: indication }));
    const requested_sprint = [...new Set(rows.map(item => item?.requested_sprint))].map(requested_sprint => ({ label: requested_sprint?.toUpperCase(), value: requested_sprint }));
    const request_brand = [...new Set(rows.map(item => item?.request_brand))].map(request_brand => ({ label: request_brand?.toUpperCase(), value: request_brand }));

    setFilterOptions({
      requester: requesterNames,
      status:statusNames,
      actual_sprint:actual_sprint,
      indication:indicationOptions,
      requested_sprint: requested_sprint,
      request_brand: request_brand
    });
    }
    let filterRows = [...rows];

    // Apply filters only if there are filter values set
    if (Object.values(filterValues).some(value => value !== '')) {
      Object.keys(filterValues).forEach((key) => {
        const filterValue = filterValues[key];
        if (Array.isArray(filterValue) && filterValue?.length > 0) {
          filterRows = filterRows.filter(row => {
            const rowValue = row[key]?.toString()?.toLowerCase();
            // Check if any of the values in filterValue array match rowValue
            return filterValue?.some(val => rowValue?.includes(val?.toLowerCase()));
          });
        } else {
          filterRows = filterRows.filter(row => {
            if (row[key]?.toString()?.toLowerCase()?.includes(filterValues[key]?.toLowerCase())) {
              return true;
            }
          });
        }
      });
      setClearFilter(false)
      setRows(filterRows);
    }

    // Update the state with filtered rows
    setRows(filterRows);
  }, [filterValues, rows]);

  const filter = (value) => {
    if (value) {

      setRows([ ...rows.filter(row => {
        let found = false;
        filterColumns.forEach((column) => {
          if (row[column]?.toString().toLowerCase().includes(value)) {
            found = true;
            return
          }
        })
        return found;
      })])
    } else {
      setRows(rows);
    }
  }

  const sort = (value, order) => {
    if (value === '')
    {
      return [ ...sortedRows ]
    }
    const returnValue = order === 'desc' ? 1 : -1;
    setSortKey(value)

    let newRows = [ ...sortedRows ]
    newRows.sort((a, b) => {
      return a[value] > b[value] ? returnValue * -1 : returnValue
    })
    setRows(newRows)
  }

  const updateOrder = () => {
    const updatedOrder = order === 'asc' ? 'desc' : 'asc';

    setOrder(updatedOrder);
    sort(sortKey, updatedOrder)
  }

  const sortAndOrder = (value) => {
    if (value === sortKey) {
      updateOrder();
      return;
    }
    sort(value, order);
  }

  // Update the state when shown headers are changed via filter or request
  useEffect(() => {
    //updateHeaders(displayKey)
  }, [displayKey] )

  function updateHeaders (headerKey) {
    // If displayKey is passed, use any keys in displayKey with the value of true as headers
    if (!headerKey) return;
    setShownHeaders(Object.keys(headerKey).map((key) => {
      if (headerKey[key]?.display == true) {
        return key;
      }
    }).filter((key) => key !== undefined));
  }

  const openModal = (row, type) => {
    setEditable(canEdit(row, type));
    let newrow = {...row};
    switch (type) {
      case 'clone':
        setModalHeading('Clone Insight');
        delete newrow.id;
        delete newrow.insight_key;
        delete newrow.requester;
        newrow.title='';
        newrow.description='';
        newrow.business_unit='';
        newrow.business_rationale='';
        newrow.request_brand='';
        newrow.requested_sprint='';
        newrow.formType='clone';
        break;
      case 'update':
        setModalHeading('Update Insight');
        newrow.formType='update';
        break;
      case 'clone_request':
        setModalHeading('Clone Request');
        delete newrow.id;
        delete newrow.insight_key;
        delete newrow.requester;
        if (newrow.capability !== 'br_insight' && newrow.capability !== 'cloudtag_insight') {
          newrow.title = row.title;
          newrow.description = row.description;
          newrow.business_unit = row.business_unit;
          newrow.business_rationale = row.business_rationale;
          newrow.request_brand = row.request_brand;
          newrow.requested_sprint = row.requested_sprint;
        } else {
          // If the condition is not met, clear these fields
          newrow.title='';
          newrow.description='';
          newrow.business_unit='';
          newrow.business_rationale='';
          newrow.request_brand='';
          newrow.requested_sprint='';
          newrow.br_insight_type = 'existing';
          newrow.formType='update';
        }
        newrow.formType = 'clone';
        break;
      case 'edit':
        setModalHeading('Edit Request');
        newrow.formType='edit';
        break;
      case 'view':
        setEditable(false);
        setModalHeading('View Request');
        newrow.formType='view';
        break;
    }
    newrow['action'] = null;
    setFormData({ ...newrow });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const canEdit = (row, type='edit') => {
    if ( !isRequester() ) {
      return false;
    }

    if ( type == 'edit' && row['status']?.toLowerCase() !== 'draft') {
      return false;
    }

    if(row['status']?.toLowerCase() === 'draft'){
      return true
    }

    if (userData.role === 'Admin' && row['status']?.toLowerCase() !== 'draft') {
      return true;
    }

    if (isRequester() && (type === 'clone' || type === 'clone_request' || type === 'update')) {
      return true;
    }

    return false;
  }

  const canUpdate = (row) => {
    if (!isRequester()) {
      return false;
    }

    if (row['status']?.toLowerCase() === 'draft') {
      return true;
    }

    if (userData.role === 'Admin' && row['status']?.toLowerCase() !== 'draft') {
      return true;
    }

    return false;
  }

  const isRequester = () => {
    if (userData.role === 'Admin' || userData.role === 'Requester') {
      return true;
    }
    return false;
  }

  const hasActions = () => {
    return actionButtons != null && actionButtons.length > 0;
  };
  const cleanCell = (value, columnKey) => {
    value = formatValue(value, columnKey, true);
    value = value.toString().replace(/"/g, '""');
    value = value.toString().replace(/&amp;/g, '&');
    value = value.toString().replace(/&gt;/g, '>');
    value = value.toString().replace(/&lt;/g, '<');
    value = value.toString().replace(/&quot;/g, '""');
    value = value.toString().replace(/&apos;/g, "'");
    return value;
  }

  const exportToCsv = () => {
    //let csvContent = "data:text/csv;charset=utf-8,";
    let showHeaderCopy = shownHeaders;

    // Added FTE Fields and capability to CSV for ER only
    if (title === 'Enhancement Requests') {
      const requestdColumn = ['capability','effort_enhancement_request', 'effort_granular_request', 'effort_po_sfo', 'effort_po_bia_ds', 'effort_dtt_sfo', 'effort_o2', 'effort_oppmodels_bia']
      requestdColumn.forEach((item) => {
        showHeaderCopy.push(item)
      })
    }

    let csvContent = '"' + showHeaderCopy.map((value) => { return cleanCell(formatHeaderValue(value))}).join('","') + '"\n';

    if (isTable(sortedRows)) {
      sortedRows.map((row, index) => {
        showHeaderCopy.map((columnKey, colIndex) => {
            if (colIndex > 0) {
              csvContent += '","';
            } else {
              csvContent += '"';
            }
            var value = columnKey === 'cardnumber' ? cleanCell(typeof row[columnKey] == "string" ? (row?.[columnKey] || "") : (row?.[columnKey]?.['props']?.['children'] || ""), columnKey) : cleanCell(row[columnKey], columnKey);
            csvContent += value;
          })
          csvContent += '"\n';
      })
    }
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const encodedUri = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.style.display = "none";
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", title + ".csv");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getFormattedCellValue = (value) => {
    let innerHTMLContent = ""
    if (!value) {
      return ''
    }

    // handle Quill Formatted Text
    let quillValue = null;
    try {
      quillValue = JSON.parse(value);
    } catch (error) {
      quillValue = null;
    }

    if (quillValue != null && quillValue.ops) {
      let hiddenDiv = document.createElement('div');
      hiddenDiv.style.display = 'none';
      const quill = new Quill(hiddenDiv, { theme: 'snow' });
      quill.setContents(quillValue);
      innerHTMLContent = quill.getSemanticHTML();
      hiddenDiv.remove();
      //Ordered and unordered lists are unstyled by default. Add classes to to bring back the bullet lists and numbers
      if (innerHTMLContent.includes("<ol") || innerHTMLContent.includes("<ul")) {
        innerHTMLContent = innerHTMLContent.replaceAll("<ol", `<ol class="list-decimal list-inside"`).replaceAll("<ul", `<ul class="list-disc list-inside"`);
      }
      let tempContainer = document.createElement('div');
      tempContainer.innerHTML = innerHTMLContent;
      const ulElements = tempContainer.querySelectorAll('ul');
      ulElements.forEach(ul => {
          const liElements = ul.querySelectorAll('li');
          liElements.forEach(li => {
            li.innerHTML = '• ' + li.innerHTML;
          });
      });
      innerHTMLContent = tempContainer.innerHTML;
    }
    return innerHTMLContent;
  }

  const exportToExcel = () => {
    let xlsContent = '<html><head><meta charset="utf-8"><style>';
    xlsContent += 'table { border-collapse: collapse; } td, th { border: 1px solid black; padding: 1px; }';
    xlsContent += '</style></head><body><table>';

    let showHeaderCopy = shownHeaders;

    // Added FTE Fields and capability to XSL for ER only
    if (title === 'Enhancement Requests') {
      const requestdColumn = ['capability', 'effort_enhancement_request', 'effort_granular_request', 'effort_po_sfo', 'effort_po_bia_ds', 'effort_dtt_sfo', 'effort_o2', 'effort_oppmodels_bia']
      requestdColumn.forEach((item) => {
        showHeaderCopy.push(item)
      })
    }

    xlsContent += '<tr>' + showHeaderCopy.map(header => `<th>${cleanCell(formatHeaderValue(header))}</th>`).join('') + '</tr>';

    sortedRows.forEach(row => {
      xlsContent += '<tr>' + showHeaderCopy.map(columnKey => {
        if (columnKey === 'cardnumber') {
          if (typeof row[columnKey] == "string") {
            return `<td>${cleanCell(row?.[columnKey] || "", columnKey)}</td>`
          } else {
            return `<td>${cleanCell(row?.[columnKey]?.['props']?.['children'] || "", columnKey)}</td>`
          }
        } else if (columnKey === 'br_suggestion_text' || columnKey === 'br_insight_text' || columnKey === 'cloud_tag') {
          return row[columnKey] ?
            `<td role='cell'>${getFormattedCellValue(row[columnKey], columnKey)}</td>` : `<td></td>`
        } else {
          return `<td>${cleanCell(row[columnKey], columnKey)}</td>`
        }
      }).join('') + '</tr>';
    });

    xlsContent += '</table></body></html>';
    const blob = new Blob([xlsContent], { type: 'application/vnd.ms-excel;charset=utf-8;' });
    const encodedUri = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", title + ".xls");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function TableBody(props) {
    return <tbody>
      { isTable(sortedRows) && sortedRows.map((row, index) => (
        <tr className='align-left' role='row' key={index}>
          <td role='cell'>
           { hasActions() === true && actionButtons.map((button, i) => {
              return (
                button.isVisible(userData.role, row) && <a href="#" key={"actionButton-" + button.iconLabel + {i}} onClick={() => button.onClick(row, {setEditable, setModalHeading, setFormData, setIsModalOpen}) }><LdsIcon name={button.iconName} label={button.iconLabel} inline/></a>
              );
            })
          }
          { cloneModal && isRequester() && <a href='#' onClick={()=>{openModal(row, 'clone')}}><LdsIcon name='Files' label='Clone Insight' inline/></a> }
          { cloneRequestModal && isRequester() && <a href='#' onClick={()=>{openModal(row, 'clone_request')}}><LdsIcon name='Files' label='Clone Request' inline/></a> }
          { updateModal && canUpdate(row) && <a href='#' onClick={()=>{openModal(row, 'update')}}><LdsIcon name='PencilSimpleLineFill' label='Create Request to Update Insight' inline /></a> }
          { editModal && canEdit(row) && <a href='#' onClick={()=>{openModal(row, 'edit')}}><LdsIcon name='PencilSimpleLineFill' label='Edit Request' inline /></a> }
          { viewModal && <a href='#' onClick={()=>{openModal(row, 'view')}}><LdsIcon name='EyeFill' label='View Request' inline /></a> }
          </td>
          { shownHeaders.map((columnKey, colIndex) => {
              return (
                <td role='cell' key={columnKey} title={row[columnKey]} style={{textAlign:'left', maxWidth:200, overflow:'hidden'}}>{formatValue(row[columnKey], columnKey)}</td>
              );
            })
          }
          <td>
              {title==='Requests'}
          </td>
        </tr>
      ))}
      </tbody>
  }

  const isExportButtonVisible = (title === "Consolidated Insights Library" || title === 'Enhancement Requests') && !loading ? true : false;
  const IsCILFormAdmin = title === "Consolidated Insights Library Management" ? true: false;

  return (
    <>
      <LdsModal
          modalId="editRequestModal"
          open={isModalOpen}
          setModalOpen={setIsModalOpen}
          heading={modalHeading || "Edit Request"}
          persistent
      >
        <LdsButton
          clearDefaultClasses
          className="lds-button-clear-style lds-modal-close-btn focus icon"
          onClick={() => setIsModalOpen(false)}
          icon="X"
          iconOnly
        />
        <RequestForm closeModal={closeModal} formData={formData} editable={editable}/>
      </LdsModal>
      <div className='controls' style={{display:'grid', gridTemplateColumns:'500px 500px', justifyContent: 'space-between', alignItems:'center' }}>
      { title && <h2>{title}</h2> }
      { children }
        <div className='controls' style={{ display: 'grid', gridTemplateColumns: '240px 240px', justifyContent: 'space-between', alignItems: 'center' }}>
          {isExportButtonVisible && <LdsButton id="exportCsvButton" value="exportCsv" label="exportCsv" name="exportCsv" onClick={exportToCsv} >Export To CSV</LdsButton>}
          {isExportButtonVisible && <LdsButton id="exportExcelButton" value="exportExcel" label="exportExcel" name="exportExcel" onClick={exportToExcel} >Export To Excel</LdsButton>}
        </div>
      </div>
      {
        (loading &&
          <div className='row justify-content-md-center'>
            <div className='col-2' style={{textAlign:'center'}}>
              <LdsLoadingSpinner style={{ alignItems:'center' }} size={70} animationSpeed={1500}/>
            </div>
          </div>
        ) ||
        (!loading &&
          <table id="requestTable" className='lds-table' style={{ 'height': 'calc(100vh - 112px - 72px)', 'position': 'relative' }}>
            <thead style={{ 'position': 'sticky', 'top': '0px', 'background': 'white', 'height': '130px' }}>
              <tr className='align-left' style={{ 'position': 'relative' }}>
                <th scope='col'><strong></strong>{(showFilters && <a href='#' onClick={clearFilterValues}><LdsIcon name='XCircleFill' label="Clear filters" inline style={{ 'position': 'absolute', 'bottom': '15px' }} /></a>)}</th>
                {isTable(rows) && shownHeaders.map((val) => {
                  if (sortKey === val) {
                    return (
                      <th scope='col' key={val} style={{ 'minWidth': displayKey[val]?.minWidth || '70px', 'position': 'relative' }}>
                        <strong onClick={(event) => sortAndOrder(val)}>{formatHeaderValue(val)}</strong>
                        <LdsIcon name={order === 'asc' ? 'CaretUp' : 'CaretDown'} inline />
                        {(showFilters && <LdsTextField key={'filter-' + val} id={'filter-' + val} style={{ 'position': 'absolute', 'bottom': '5px', 'width': '90%' }} value={filterValues[val]} onChange={updateFilterValues} ></LdsTextField>)}
                      </th>
                    )
                  } else {
                    return (
                      <th scope='col' key={val} style={{ 'minWidth': displayKey[val]?.minWidth || '70px', 'position': 'relative' }}>
                        <strong onClick={(event) => sortAndOrder(val)}>{formatHeaderValue(val)}</strong>
                        {
                          (isExportButtonVisible || IsCILFormAdmin) && requestMultiSelectColumn.includes(val) ?
                            ((showFilters && <MultiSelectDropdown
                              name={val}
                              onChange={updateMultiSlectFilterValues}
                              filterOptions={filterOptions}
                              clearFilter={clearFilter} />
                            ))
                            : (showFilters && <LdsTextField
                              key={'filter-' + val}
                              id={'filter-' + val}
                              style={{ 'position': 'absolute', 'bottom': '5px', 'width': '90%', }}
                              value={filterValues[val]}
                              onChange={updateFilterValues}>
                            </LdsTextField>
                            )}
                      </th>
                    )
                  }
                })}
            <td>
            {title==='Requests'}
            </td>
            </tr>
            </thead>
            { (sortedRows.length > 0) && <TableBody /> }
            {(!sortedRows.length && <tbody><tr><td colSpan='200'>No results found</td></tr></tbody>)}
          </table>
        )
      }
    </>
  );
};
